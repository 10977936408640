// TODO: Maybe fix the values to 'Chrome', 'Internet Explorer', etc. Currently
// this values needs to be as they are becuse they are going to analytics,
// callstats, etc.

export const CHROME = 'chrome';

export const OPERA = 'opera';

export const FIREFOX = 'firefox';

export const INTERNET_EXPLORER = 'iexplorer';

export const SAFARI = 'safari';

export const NWJS = 'nwjs';

export const ELECTRON = 'electron';

export const REACT_NATIVE = 'react-native';

export const UNKNOWN = 'unknown';
